import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import BacklinkingStrategies from "../images/BacklinkingStrategies.png"
import LocalSEO from "../images/LocalSEO.png"
import MeasurableResults from "../images/MeasurableResults.png"
import NiceSatmetrixNew from "../images/NiceSatmetrixNew.svg"
import PredictiveContent from "../images/PredictiveContentMarketing.png"
import SEORightRoundTwo from "../images/SEORightRoundTwo.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapefour from "../images/bannerElement/white-design-element-seo.png"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import houndify from "../images/houndify.png"
import BuildingAuthority from "../images/icons/Seo/BuildingAuthority.svg"
import InformativeContent from "../images/icons/Seo/InformativeContent.svg"
import TechnicalSEOCompliance from "../images/icons/Seo/TechnicalSEOCompliance.svg"
import partners from "../images/partners.svg"
import "../styles/404.css"

import image1 from "../images/image-one.svg"
import image3 from "../images/ma-image.svg"

const SEO = ({ siteTitle }) => {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button")

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded")

      if (itemToggle === "false") {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false")
        }
        this.setAttribute("aria-expanded", "true")
      } else {
        // Check if the clicked accordion is already open, if yes, do nothing
        if (itemToggle === "true") {
          return
        }
        this.setAttribute("aria-expanded", "false")
      }
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion))

    return () => {
      items.forEach(item => item.removeEventListener("click", toggleAccordion))
    }
  }, [])
  const [imageSrc, setImageSrc] = useState(LocalSEO)
  const [fadeIn, setFadeIn] = useState(false)

  const changeImage = newImageSrc => {
    setFadeIn(true)
    setTimeout(() => {
      setImageSrc(newImageSrc)
      setFadeIn(false)
    }, 300)
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>SEO | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="seo demand-generation">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>
                      Unlock sustained <br />
                      organic growth
                    </h1>
                    <p>Propel your brand to new heights with SEO expertise</p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="/contact-us/"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Maximize your discoverability online</h2>
              <p>
                Our SEO experts leverage data-driven insights, including on-page
                optimization, technical SEO, content marketing, and link
                building strategies, to optimize your entire online presence.
                Our comprehensive approach ensures your website climbs the
                Search Engine Result Pages (SERPs) for explosive organic growth.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Our expertise goes beyond basics</h2>
                  <div class="accordion">
                    <div class="accordion-item">
                      <button
                        id="accordion-button-1"
                        aria-expanded="true"
                        onClick={() => changeImage(LocalSEO)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Local SEO</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We help you dominate local search results and connect
                          with customers in your area.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-2"
                        aria-expanded="false"
                        onClick={() => changeImage(PredictiveContent)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Predictive Content Marketing
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We create data-driven content that resonates with your
                          target audience and drives organic traffic.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-3"
                        aria-expanded="false"
                        onClick={() => changeImage(BacklinkingStrategies)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          Backlinking Strategies
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We build high-quality backlinks to your website,
                          boosting its authority and visibility.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-4"
                        aria-expanded="false"
                        onClick={() => changeImage(MeasurableResults)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Measurable Results</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          A clear Return On Investment (ROI) through increased
                          leads and revenue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="img-wrap">
                  <div className={`image-container ${fadeIn ? "fade-in" : ""}`}>
                    <img
                      alt="Seo Right"
                      width="500"
                      height="500"
                      src={imageSrc}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="BgImgDescSection">
            <div class="container">
              <h2>Our 3-step modular approach</h2>
              <div class="BgImgDesc-wrapper">
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img src={image1} alt="Image one" />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="AI image"
                        width="100"
                        height="100"
                        src={TechnicalSEOCompliance}
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Technical SEO Compliance</h5>
                    <ul class="bullet-point">
                      <li>Fixing existing technical SEO compliance issues</li>
                      <li>Addressing website architecture and themes</li>
                      <li>Coding and schema for better visibility</li>
                      <li>
                        On-page enhancements for local, mobile, and app store
                        optimization where needed
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img src={image1} alt="Image two" />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="Proactive"
                        width="100"
                        height="100"
                        src={InformativeContent}
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Informative Content</h5>

                    <ul class="bullet-point">
                      <li>Data-driven predictive content optimization</li>
                      <li>SEO optimization across various digital assets</li>
                      <li>Content assets that can be shared and syndicated</li>
                    </ul>
                  </div>
                </div>
                <div class="each-card">
                  <div class="img-wrap">
                    <span class="imgcover">
                      <img
                        src={image3}
                        alt="Image two"
                        width="164"
                        height="164"
                      />
                    </span>
                    <div class="image-holder">
                      <img
                        alt="Tech Expert"
                        width="100"
                        height="100"
                        src={BuildingAuthority}
                      />
                    </div>
                  </div>
                  <div class="text-wrap">
                    <h5>Building Authority</h5>
                    <ul class="bullet-point">
                      <li>Syndicating content</li>
                      <li>Social sharing</li>
                      <li>Targeting sites linking to multiple competitors</li>
                      <li>Local SEO (where applicable)</li>
                      <li>App store optimization (where applicable)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection2">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Our focus is on your success</h2>
                  <p>
                    We focus on driving tangible results that propel your
                    business forward. We leave no stone unturned in transforming
                    your online presence, ensuring it consistently delivers
                    value and achieves your goals.
                  </p>
                  <ul class="bullet-point">
                    <li>Increased Organic Traffic</li>
                    <li>Better Content Performance</li>
                    <li>Improved Brand Awareness</li>
                    <li>Growth in Quality Leads</li>
                    <li>Enhanced Conversion</li>
                  </ul>
                </div>
                <div class="img-wrap">
                  <img
                    alt="Copy write"
                    width="500"
                    height="500"
                    src={SEORightRoundTwo}
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="BussinessColSection">
            <div class="container">
              <h2>We cater to diverse industries</h2>
              <p class="businesscol-desc">
                Our SEO services drive customer acquisition through optimized
                content, technical enhancements, and link-building strategies
                for B2C, B2B, B2SMB, Programmatic & multi-location businesses.
                With 15 years of experience spanning 200+ B2B and B2C companies,
                we expertly navigate strategies proven to accelerate demand and
                success in SEO for diverse industries.
              </p>
              {/* <div>
                <div class="bussinesscol-wrapper">
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2B" width="200" height="200" src={B2B} />
                      <p>B2B</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2C" width="200" height="200" src={B2C} />
                      <p>B2C</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="B2SMB" width="200" height="200" src={B2SMB} />
                      <p>B2SMB</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img
                        alt="Ecommerce"
                        width="200"
                        height="200"
                        src={Ecommerce}
                      />
                      <p>Programmatic</p>
                    </div>
                  </div>
                  <div class="each-bussinesscol">
                    <div class="img-wrap">
                      <img alt="SaaS" width="200" height="200" src={Saas} />
                      <p>Multi-location</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
          <section id="CaseStudy">
            <div class="sliderwrap">
              <div class="contentholder">
                <div class="container">
                  <div class="descp">
                    <p class="title">Case Study</p>
                    <div class="img-wrap">
                      <img src={houndify} alt="Houndify Logo" />
                    </div>
                    <div>
                      <h2>SEO optimization spikes web traffic by 150%</h2>
                    </div>
                    <div>
                      <p>
                        Pioneering solutions for a voice-enabled world. They
                        help top brands build custom voice assistants—powered by
                        Houndify.
                      </p>
                    </div>
                    <a
                      className="button"
                      target="_blank"
                      href="https://www.position2.com/position2-houndify-case-study.pdf"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex1" style={{backgroundImage: "url(" + NiceSatmetrixNew + ")",}} >
            <div class="holder">
              <div class="container">
                <div class="testimonial-wrapper">
                  <div class="left-content">
                    <div class="animation-text">
                      <span class="quote"></span>
                      <div>
                        <p>
                          Position²’s holistic approach to SEO services looks at
                          internal factors such as website architecture and
                          content optimization, as well as external factors such
                          as link popularity, channel optimization, and social
                          media promotion, which helped us achieve the best
                          possible results from organic search.
                        </p>
                      </div>
                      <div class="testi-designation">
                        <p class="designation">
                          <strong>
                            Nikhin Dinesh <br />
                          </strong>
                          Demand Gen Manager
                          <br />
                          Nice-satmetrix
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-img">
                    <img
                      alt="NiceSat Image"
                      width="550"
                      height="550"
                      src={NiceSatmetrixNew}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ParallexSection" class="parallex2">
            <div class="holder">
              <div class="container">
                <div class="TextImgSection">
                  <div class="text-image-holder">
                    <div class="text-wrap">
                      <div class="animation-text">
                        <div>
                          <h3>Partners/Tools</h3>
                        </div>
                        <div>
                          <p>
                            We partner with technology giants. We use the latest
                            cutting-edge tools. All for one reason: so that you
                            have a firm footing and excel in organic marketing.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mobile-img">
                      <img
                        alt="Partners"
                        width="550"
                        height="550"
                        src={partners}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Unlock explosive organic growth - Book an SEO audit today</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

SEO.propTypes = {
  siteTitle: PropTypes.string,
}

SEO.defaultProps = {
  siteTitle: ``,
}

export default SEO
